html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  background-color: #b3a798;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

.content {
  width: 64em;
  -webkit-user-select: none;
  user-select: none;
  background-color: tan;
  margin: 0 auto;
  box-shadow: 5px 0 5px #00000040, -5px 0 5px #00000040;
}

.header {
  color: gold;
  text-align: center;
  text-shadow: 2px 2px 2px #00000080;
  margin-bottom: .5em;
  font-family: novelgothicnormal;
  font-size: 4.5em;
}

.footer {
  height: 6em;
  padding: 2em;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px;
}

td {
  white-space: nowrap;
  vertical-align: middle;
  background-color: #e1e2e3;
  border-radius: .75em;
  padding: .25em .75em;
  font-size: 2em;
  box-shadow: -2px -2px 2px #00000080;
}

td:first-child {
  width: 100%;
}

.notes {
  width: 8em;
  text-indent: .25em;
  background-color: #e1e2e3;
  font-family: arial, sans-serif;
  font-size: 1em;
  display: inline-block;
  position: relative;
  top: -.25em;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  height: 1.5em;
  width: 1.5em;
  box-sizing: border-box;
  border: 1px solid #343536;
  border-radius: .1em;
  margin-right: 1em;
  display: inline-block;
  position: relative;
  top: .25em;
}

input[type="checkbox"]:active + label {
  border-color: red;
}

input[type="checkbox"]:checked + label {
  background-image: url("X_mark_18x18.5214021e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1em;
}

.section {
  margin: 1.5em;
  font-family: special_eliteregular;
}

.section-header {
  text-transform: uppercase;
  text-align: center;
  padding: .25em;
  font-size: 2.5em;
  font-weight: 700;
}

.rounded {
  border-radius: 5px;
}

.button {
  cursor: pointer;
  background-color: #e1e2e3;
  border: 1px solid #000;
  border-radius: .75em;
  margin: .5em;
  padding: 1em;
  font-family: special_eliteregular;
  font-size: 1.5em;
  display: inline-block;
  position: relative;
  box-shadow: 3px 3px 2px #00000080;
}

.button:hover {
  background-color: #c1c2c3;
}

.button:active {
  box-shadow: none;
  top: 3px;
  left: 3px;
}

/*# sourceMappingURL=index.bd44aa68.css.map */
