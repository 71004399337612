/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : May 28, 2014, 4:09:09 PM
    Author     : candrews
*/

html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

body {
    padding: 0px;
    margin: 0px;
    font-size: 10px;
    background-color: rgb(179, 167, 152);
}

.content {
    background-color: tan;
    width: 64em;
    margin: 0px auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-shadow: rgba(0,0,0,0.25) 5px 0px 5px, rgba(0,0,0,0.25) -5px 0px 5px;
}

.header {
    font-family: 'novelgothicnormal';
    font-size: 4.5em;
    margin-bottom: .5em;
    color: gold;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
}

.footer {
    height: 6em;
    padding: 2em;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;
}

td {
    font-size: 2em;
    background-color: #e1e2e3;
    padding: .25em .75em;
    border-radius: .75em;
    white-space: nowrap;
    box-shadow: -2px -2px 2px rgba(0,0,0,0.5);
    vertical-align: middle;
}

td:first-child {
    width: 100%;
}

.notes {
    position: relative;
    top: -0.25em;
    display: inline-block;
    width: 8em;
    text-indent: .25em;
    font-size: 1em;
    font-family: arial, sans-serif;
    background-color: #e1e2e3;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"] + label {
    position: relative;
    top: .25em;
    margin-right: 1em;
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
    border: 1px solid #343536;
    border-radius: .1em;
    box-sizing: border-box;
}

input[type="checkbox"]:active + label {
    border-color: red;
}

input[type="checkbox"]:checked  + label {
    background-image: url('images/X_mark_18x18.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1em;
}

.section {
    font-family: 'special_eliteregular';
    margin: 1.5em;
}

.section-header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.5em;
    padding: .25em;
    text-align: center;
}

.rounded {
    border-radius: 5px;
}

.button {
    position: relative;
    display: inline-block;
    font-family: 'special_eliteregular';
    margin: .5em;
    padding: 1em;
    font-size: 1.5em;
    cursor: pointer;
    border-radius: .75em;
    border: 1px solid black;
    box-shadow: 3px 3px 2px rgba(0,0,0,0.5);
    background-color: #e1e2e3;
}

.button:hover {
    background-color: #c1c2c3
}

.button:active {
    box-shadow: none;
    top: 3px;
    left: 3px;
}